/**
 * Global
 * resets, variables, mixins, and some utility CSS classes
 */

// Fonts
$main-font:    		'Montserrat Alternates', sans-serif;
$secondary-font: 	'BurfordInline';

// Colors
$main-color: 		#28748f;
$secondary-color: 	#fff799;
$white-color: 		#ffffff;
$shadow-color: 		#303030;

// Section height
$section-height: 	100vh;

* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
	body {
		background: $main-color;
		color: $white-color;
		font-family: sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		&.fix {
		    display:inline-block;
		    width:100%;
		}
		main {
			height: $section-height;
			overflow-y: scroll;
			/*scroll-snap-points-y: repeat($section-height);*/
			scroll-snap-type: y mandatory;
			/*scroll-snap-type: mandatory;*/
			scroll-behavior: smooth;
		}
		h1.sheep:after,
		h2.sheep:after {
			background-image: url('../img/image-sheep.png');
		    background-position: right top;
		    background-repeat: no-repeat;
		    -webkit-background-size: 100%;
		    background-size: 100%;
		    content: '';
			height: 4rem;
			position: absolute;
			right: -1.25rem;
			width: 4rem;
		}
		h1	{
			color: $white-color;
			font-family: $secondary-font;
			font-size: 5rem;
			line-height: .7;
			position: relative;
			&.sheep {
				&:after {
					bottom: -15px;
				}
			}
		}
		h2 {
			color: $secondary-color;
			font-family: $secondary-font;
			font-size: 5rem;
			letter-spacing: -0.7px;
			line-height: .7;
			&.sheep {
				&:after {
					top: 15px;
				}
			}
		}
		p {
			font-family: $main-font;
			font-size: 1.5rem;
		}
	}
	&[lang="fr"] {
		h1, h2 {
			font-size: 5rem;
			letter-spacing: -0.7px;
			line-height: .7;
			&.sheep {
				&:after {
					right: -2.5rem;
				}
			}
		}
		h2 { 
			line-height: .8;
		}
		p {
			font-size: 1.5rem;
		}
		.contact {
			p {
				font-size: 1rem;
			}
		}
	}
}

::selection {
	background-color: $white-color;
}
:focus {
    outline: 1px dotted $secondary-color !important; 
    outline: 5px auto $secondary-color !important; 
}

@include respond-below(md) {
	html {
		&[lang="fr"] {
			body {
				h1, h2 {
					font-size: 3.5rem;
				    line-height: .75;
				}
				p {
					font-size: 1rem;
				}
			}
		}
		body {
			h1, h2 {
				font-size: 3.5rem;
				&.sheep {
					&:after {
						height: 3rem;
						width: 3rem;
					}
				}
			}
			p {
				font-size: 1rem;
			}
		}
	}
}

@include respond-below(sm) {
	html {
		&[lang="fr"] {
			body {
				h1 {
					font-size: 3rem;
					&.sheep {
						&:after {
							right: auto;
						}
					}
				}
				h2 {
					font-size: 2.8rem;
					position: relative;
					&.sheep {
						&:after {
							bottom: -15px;
							top: auto;
							right: auto;
						}
					}
				}
			}
		}
		body {
			h1 {
				&.sheep {
					&:after {
						right: auto;
					}
				}
			}
			h2 {
				position: relative;
				&.sheep {
					&:after {
						bottom: -15px;
						top: auto;
						right: auto;
					}
				}
			}
		}
	}
}

@include respond-below(xs) {
	html {
		&[lang="fr"] {
			body {
				h1 {
					&.sheep {
						&:after {
							display: none;
						}
					}
				}
				h2 {
					position: relative;
					&.sheep {
						&:after {
							display: none;
						}
					}
				}
			}
		}
		body {
			h1 {
				&.sheep {
					&:after {
						display: none;
					}
				}
			}
			h2 {
				position: relative;
				&.sheep {
					&:after {
						display: none;
					}
				}
			}
		}
	}
}
