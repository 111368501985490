/**
 * for components such as buttons, navbars, cards, anything that is reusable
 */
.navbar-dark {
	.navbar-nav {
		.nav-link {
			color: $white-color;
		}
	}
	.navbar-toggler {
		border-color: transparent;
		.navbar-toggler-icon {
		    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
		}
	}
	.right-nav {
		color: $secondary-color;
		li {
			&+li {
			  	border-left: 1px solid $secondary-color;
			}
			.nav-link {
				color: $secondary-color !important;
				&:hover {
					color: rgba(255, 247, 153, .75) !important;
					/*text-decoration: underline;*/
				}
			}
		}

	}
}

.main-logo {
	max-width: 87px;
}
/**
 * Bullets 
 */
.side {
    bottom: 0;
	height: 100%;
    opacity: 1;
    padding: calc(5.56% + 50px) 4%;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 99;
    .navigation {
    	display: table;
    	height: 100%;
    	ul {
    		display: table-cell;
    		vertical-align: middle;
    		li {
    			background: transparent;
    			display: block;
    			fill: $white-color;
    			margin: 0 -9px;
    			padding: 9px;
    		 	position: relative;
    		 	a {
    		 		background: $white-color;
    		 		-webkit-border-radius: 7px;
    		 		border-radius: 7px;
    		 		-webkit-box-shadow: 0 0 1px $shadow-color;
    		 		box-shadow: 0 0 1px $shadow-color;
    		 		display: block;
    		 		height: 10px;
    		 		opacity: .5;
    		 		padding: 0;
    		 		-webkit-transition: opacity .15s, background .15s;
    		 		transition: opacity .15s, background .15s;
    		 		width: 10px;
    		 		&.active {
						background: $white-color;
						opacity: 1;
					}
    		 	}
    		}
    	}
    }
}

/**
 * Accordion
 */
.accordion {
	margin-top: 10px;
	.card {
		background: transparent;
	  	border: none;
		.card-header {
			background: transparent;
		  	border: none;
		  	padding-bottom: 0;
		  	h3 {
		  	 	font-family: $main-font;
		  	 	font-weight: 100;
	  	 	  	.btn {
	  	 	  	  	color: $white-color;
	  	 	  	  	font-size: 1.5rem;
	  	 	  	  	font-weight: lighter;
	  	 	  	  	padding: 0;
	  	 	  	  	text-align: left;
	  	 	  	  	width: 100%;
	  	 	  	  	&.btn-link {
	  	 	  	  		&:hover,
	  	 				&:focus {
	  	 				  	text-decoration: none;
	  	 				}
	  	 	  	  	}
	  	 	  	}
		  	}
		}
		.card-body {
			font-family: $main-font;
			line-height: 1.1;
		}
	}

}

.image {
	border-radius: 100%;
	max-height: 50vh;
	&.first-image {
		max-height: 50vh;
	}
}

.sheep-sm {
	max-width: 2.7rem;
	vertical-align: text-top;
	float: right;
}

/*@media (max-height: 666px) {
  .showhight768 {
    display: none;
  }
}*/

.view-more {
	margin-top: .7rem;
	a {
		color: $secondary-color;
		font-family: $secondary-font;
		font-size: 2.7rem;
		letter-spacing: -0.7px;
		line-height: .7;
		&:hover {
			text-decoration: none;
		}
	}
}

.hospitality {
	font-family: $main-font;
	font-size: 2rem;
	font-weight: lighter;
}

.contact {
	p {
		font-size: 1rem;
		a {
			color: $white-color;
		} 
	}
}

.icon {
	height: 20px;
	width: 20px;
	path {
		fill: $white-color;
	}
}

.next {
	bottom: 10px;
	position: absolute;
	text-align: center;
	width: calc(100% - 30px);;
	z-index: 1;
	.icon {
		height: 30px;
		width: 30px;
	}
}

@include respond-above(md) {
	.navbar-dark {
		.right-nav {
			li {
				&:last-child {
					.nav-link {
						padding-right: 0;
					}
				}
				.nav-link {
					padding: .1rem 1rem;
				}
			}

		}
	}
}

@include respond-below(md) {
	.accordion {
		.card {
			.card-header {
				h3 {
					.btn {
						font-size: 1rem;	
					}
				}
			}
			.card-body { 
				font-size: .9rem;
			}
		}
	}
}
@include respond-below(sm) {
	.side { display: none; }
}
@include respond-below(xs) {
	.image {
		max-height: 30vh;
		&.first-image {
			max-height: 30vh;
		}
	}
}