
/**
 * handles the overall layout of the web page such as container and grid systems.
 */
section {
	border-bottom: 1px solid $main-color;
	height: $section-height;
	scroll-snap-align: start;
	overflow-y: scroll;
	position: relative;
	&:first-child {
		.navbar {
			z-index: 101;
		}
		.nav-link {
			color: $white-color;
			font-family: $secondary-font;
			font-size: 1.7rem;
		}
		.container {
			&.align-items-center {
				height: calc(#{$section-height} - 95.33px); // Section height - navbar height
				position: relative;
			}
		}
	}
}

@include respond-below(sm) {
	html {
		body {
			section {
				&:nth-child(6){
					h2 {
						font-size: 2.3rem;
					}
				}
				h2 {
					font-size: 3rem;
				}
			}
		}
	}
}
